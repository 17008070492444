import React from 'react'
import { graphql } from 'gatsby'
import Template from 'templates/category'

const CategoryQueryTemplate = props => {
  return <Template {...props} />
}

export const pageQuery = graphql`
  query categoryQueryTemplate($id: String!) {
    category: sanityFieldGuideCategory(id: { eq: $id }) {
      title
    }
    items: allSanityFieldGuideSubcategory(
      filter: { parentCategory: { id: { eq: $id } } }
      sort: { fields: [title], order: ASC }
    ) {
      nodes {
        slug
        title
      }
    }
  }
`

export default CategoryQueryTemplate
